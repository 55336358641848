@import './assets/css/animate.css';
@import './assets/css/fonts.css';

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

html,
body {
  height: 100%;
  color: #2d3748;
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

ul:not(.list-none) li:before {
  content: '●';
  padding-right: 6px;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ew-resize;
  background: #fff;
  border: none;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.staticCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

@media screen {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  input:checked + i.material-icons {
    display: block;
  }

  #sidebar {
    top: 0;
    left: 0;
    bottom: 0;
  }

  #tabs {
    scroll-behavior: smooth;
  }

  #page {
    width: 21cm;
    min-height: 29.7cm;
    background-color: white;
  }

  #printPage {
    display: none;
  }

  #pageController {
    bottom: 25px;
  }

  #pageController > div {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body,
  body * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    visibility: hidden;
  }

  #printPage,
  #printPage * {
    visibility: visible;
    page-break-inside: avoid;
  }

  #printPage {
    width: 21cm;
    height: 29.7cm;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* margin-top: 2.5rem; */
  }
}

@media only screen and (max-width: 1023px)
{
  /* body{
    font-size: 20px;
  } */
}

/* user animation */
.user-enter {
  transform: scale(0);
  transform-origin: top right;
}
.user-enter-active {
  transform: scale(1);
  transition: transform 200ms ease;
}
.user-exit {
  transform: scale(1);
  transform-origin: top right;
}
.user-exit-active {
  transform: scale(0);
  transition: transform 200ms ease;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.blurBackground {
  filter: blur(4px);
}